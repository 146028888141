<template>
  <section class="slider">
    <b-carousel
      id="carousel-3"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="490"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <span v-for="item in slides" v-bind:key="item.id">
        <a :href="item.link" target="_blank">
          <b-carousel-slide>
            <template v-slot:img>
              <img
              class="d-block car-image-slot"
              :src="item.src">
            </template>
          </b-carousel-slide>
        </a>
      </span>
    </b-carousel>
  </section>
</template>

<script>
/* eslint-disable */

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/components/component-carousel.scss';

.slider {
  width: 100%;
}

.car-image-slot {
  height:490px !important;
  margin: 0 auto;
  width: auto;
  @include breakpoint(sm) {
    height:300px !important;
  }
  @include breakpoint(mobile) {
    height:200px !important;
  }
}
</style>
