<template>
  <div class="card">
    <div class="card-body">
      <div class="icon-text">
        <slot class="icon"></slot>
        <h5 class="card-title">{{ itemName }}</h5>
      </div>
      <p class="card-text mb-3" v-html="itemDescription"></p>
      <b-button
        @click="handleSetItem"
        variant="primary"
        class="mt-5"
        :class="{ altColor: isAltClass, 'btn btn-secondary': isAltClass, 'btn btn-primary': !isAltClass}"
      >{{
        btnText
      }}</b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'card-store-lp',
  props: {
    category: {
      type: String,
      default: '',
    },
    itemId: {
      type: String,
      default: '',
    },
    itemName: {
      type: String,
      default: '',
    },
    itemDescription: {
      type: String,
      default: '',
    },
    btnUrl: {
      type: String,
      default: '/',
    },
    btnText: {
      type: String,
      default: 'View More',
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  methods: {
    handleSetItem() {
      this.$emit('set_item', {
        itemId: this.itemId,
        category: this.category,
      })
    },
  },
  computed: {
    isAltClass() {
      return this.className === 'altColor'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.altColor {
  background-color: $secondary-color !important;
  &:hover {
    background-color: $primary-color !important;
  }
}
.icon {
  margin: 0 auto;
  max-height: 90px;
  min-height: 90px;
}
.card {
  justify-content: center;
  width: 100%;
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .icon-text {
      text-align: center;
      min-width: 90px;
    }
    .card-title {
      margin-bottom: 17px;
      margin-top: 15px;
      color: $primary-color;
      font-family: $DIN;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 44px;
      text-transform: uppercase;
      text-align: center;
    }
    .card-text {
      text-align: center;
    }
  }
  .border-bot {
    border-bottom: 8px solid $primary-color;
    border-left: none !important;
    border-right: none !important;
    margin-right: none !important;
  }

  p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
  }
}
</style>
